const genesPanels = {
  abcb1: [
    "ANTICONVULSIVOS",
    "TAXANOS",
    "TIOPURINAS",
    "DETOX",
    "PIRIMIDINAS",
    "ANTIDEPRESIVOS",
    "CARD60",
    "ANTIPSICOTICOS",
    "ANTIPARKINSONIANOS",
    "ANTI-TDAH",
    "METOTREXATO",
    "ANESTESICOS",
    "PLATINO",
    "ANALGESICOS",
    "CARD5",
    "HISTAMINA",
    "ANSIOLITICOS",
    "ANTIDEMENCIA"
  ],
  abcc2: [
    "ANTICONVULSIVOS",
    "TAXANOS",
    "DETOX",
    "ANTIDEPRESIVOS",
    "CARD60",
    "ANTIPARKINSONIANOS",
    "ANTI-TDAH",
    "RIBAVIRINA",
    "ANESTESICOS",
    "PLATINO",
    "ANALGESICOS",
    "CARD5",
    "ANSIOLITICOS"
  ],
  abcg2: ["ANESTESICOS", "CARD60"],
  adra2a: [
    "ANTICONVULSIVOS",
    "ANTIDEPRESIVOS",
    "CARD60",
    "ANTIPARKINSONIANOS",
    "ANTIPSICOTICOS",
    "ANTI-TDAH",
    "ANESTESICOS",
    "CARD5",
    "ANTIDEMENCIA"
  ],
  adrb2: [
    "ANTIDEPRESIVOS",
    "CARD60",
    "ANTI-TDAH",
    "METOTREXATO",
    "ANSIOLITICOS"
  ],
  adrb3: ["CARD60"],
  agt: [
    "ANTICONVULSIVOS",
    "CARD60",
    "ANTIPSICOTICOS",
    "ANTI-TDAH",
    "ANALGESICOS"
  ],
  apoe: ["ANTIDEPRESIVOS", "CARD60", "ANESTESICOS", "CARD5", "ANSIOLITICOS"],
  bche: [
    "ANTICONVULSIVOS",
    "ANTIDEPRESIVOS",
    "CARD60",
    "ANTIPARKINSONIANOS",
    "ANTI-TDAH",
    "ANESTESICOS",
    "ANALGESICOS",
    "CARD5"
  ],
  ces1: ["ANTIDEPRESIVOS", "CARD60", "ANTIPSICOTICOS", "ANESTESICOS", "CARD5"],
  chat: ["CARD60", "ANTI-TDAH", "CARD5"],
  chrna7: [
    "ANTICONVULSIVOS",
    "TIOPURINAS",
    "DETOX",
    "ANTIDEPRESIVOS",
    "CARD60",
    "ANTIPARKINSONIANOS",
    "ANTIPSICOTICOS",
    "ANTI-TDAH",
    "ANESTESICOS",
    "ANALGESICOS",
    "CARD5",
    "ANSIOLITICOS"
  ],
  comt: [
    "ANTICONVULSIVOS",
    "ANTIDEPRESIVOS",
    "CARD60",
    "ANTIPSICOTICOS",
    "ANTI-TDAH",
    "ANESTESICOS",
    "ANALGESICOS",
    "CARD5",
    "ANTIDEMENCIA"
  ],
  cyp1a1: ["CARD60", "ANALGESICOS", "DETOX"],
  cyp1a2: [
    "ANTICONVULSIVOS",
    "TAXANOS",
    "PIRIMIDINAS",
    "ANTIDEPRESIVOS",
    "CARD60",
    "ANTIPSICOTICOS",
    "ANTIPARKINSONIANOS",
    "ANTI-TDAH",
    "ANESTESICOS",
    "ANALGESICOS",
    "CARD5",
    "HISTAMINA",
    "ANSIOLITICOS",
    "ANTIDEMENCIA"
  ],
  cyp1b1: [
    "ANTIDEPRESIVOS",
    "CARD60",
    "ANTI-TDAH",
    "ANESTESICOS",
    "ANTIDEMENCIA"
  ],
  cyp2a6: ["ANESTESICOS", "CARD60", "ANTIPARKINSONIANOS", "CARD5"],
  cyp2b6: [
    "ANTICONVULSIVOS",
    "TAXANOS",
    "ANTIDEPRESIVOS",
    "CARD60",
    "ANTIPSICOTICOS",
    "ANTI-TDAH",
    "ANESTESICOS",
    "ANALGESICOS",
    "CARD5",
    "ANSIOLITICOS"
  ],
  cyp2c19: [
    "ANTICONVULSIVOS",
    "TAXANOS",
    "DETOX",
    "ANTIDEPRESIVOS",
    "CARD60",
    "ANTIPARKINSONIANOS",
    "ANTIPSICOTICOS",
    "ANTI-TDAH",
    "METOTREXATO",
    "ANESTESICOS",
    "PLATINO",
    "ANALGESICOS",
    "CARD5",
    "HISTAMINA",
    "ANSIOLITICOS",
    "ANTIDEMENCIA"
  ],
  cyp2c9: [
    "ANTICONVULSIVOS",
    "TAXANOS",
    "TIOPURINAS",
    "DETOX",
    "PIRIMIDINAS",
    "ANTIDEPRESIVOS",
    "CARD60",
    "ANTIPSICOTICOS",
    "ANTIPARKINSONIANOS",
    "ANTI-TDAH",
    "METOTREXATO",
    "ANESTESICOS",
    "PLATINO",
    "ANALGESICOS",
    "CARD5",
    "ANSIOLITICOS",
    "ANTIDEMENCIA"
  ],
  cyp2d6: [
    "ANTICONVULSIVOS",
    "TAXANOS",
    "DETOX",
    "PIRIMIDINAS",
    "ANTIDEPRESIVOS",
    "CARD60",
    "ANTIPARKINSONIANOS",
    "ANTIPSICOTICOS",
    "ANTI-TDAH",
    "ANESTESICOS",
    "PLATINO",
    "ANALGESICOS",
    "CARD5",
    "HISTAMINA",
    "ANSIOLITICOS",
    "ANTIDEMENCIA"
  ],
  cyp2e1: [
    "ANTICONVULSIVOS",
    "ANTIDEPRESIVOS",
    "CARD60",
    "ANTIPSICOTICOS",
    "ANTI-TDAH",
    "ANESTESICOS",
    "ANALGESICOS"
  ],
  cyp3a4: [
    "ANTICONVULSIVOS",
    "TIOPURINAS",
    "DETOX",
    "ANTIDEPRESIVOS",
    "CARD60",
    "ANTIPSICOTICOS",
    "ANTIPARKINSONIANOS",
    "ANTI-TDAH",
    "RIBAVIRINA",
    "ANESTESICOS",
    "PLATINO",
    "ANALGESICOS",
    "HISTAMINA",
    "ANTIDEMENCIA"
  ],
  cyp3a5: [
    "ANTICONVULSIVOS",
    "DETOX",
    "ANTIDEPRESIVOS",
    "CARD60",
    "ANTIPSICOTICOS",
    "ANTIPARKINSONIANOS",
    "ANTI-TDAH",
    "ANALGESICOS",
    "CARD5",
    "ANSIOLITICOS"
  ],
  cyp4f2: ["ANTICONVULSIVOS", "TAXANOS"],
  dpyd: [
    "ANTICONVULSIVOS",
    "ANTIDEPRESIVOS",
    "CARD60",
    "ANTI-TDAH",
    "ANESTESICOS",
    "PLATINO",
    "ANALGESICOS",
    "HISTAMINA"
  ],
  drd2: [
    "ANTICONVULSIVOS",
    "TIOPURINAS",
    "ANTIDEPRESIVOS",
    "CARD60",
    "ANTIPSICOTICOS",
    "ANTIPARKINSONIANOS",
    "ANTI-TDAH",
    "ANESTESICOS",
    "ANSIOLITICOS"
  ],
  fabp2: ["ANTICONVULSIVOS", "ANTIDEPRESIVOS"],
  "flot1-hla-b": ["CARD60", "CARD5"],
  g6pd: ["ANSIOLITICOS", "CARD60"],
  gabra1: [
    "CARD60",
    "ANTIPSICOTICOS",
    "ANTIPARKINSONIANOS",
    "ANESTESICOS",
    "ANALGESICOS",
    "ANTIDEMENCIA"
  ],
  gstm1: [
    "ANTICONVULSIVOS",
    "CARD60",
    "ANTIPSICOTICOS",
    "ANTI-TDAH",
    "ANESTESICOS",
    "ANALGESICOS",
    "ANTIDEMENCIA"
  ],
  gstp1: [
    "ANTICONVULSIVOS",
    "ANTIDEPRESIVOS",
    "CARD60",
    "ANTIPARKINSONIANOS",
    "ANTIPSICOTICOS",
    "ANTI-TDAH",
    "ANESTESICOS",
    "CARD5",
    "HISTAMINA"
  ],
  gstt1: [
    "ANTICONVULSIVOS",
    "CARD60",
    "ANTIPSICOTICOS",
    "ANTI-TDAH",
    "ANALGESICOS"
  ],
  "hcp-hla-b": ["ANTIDEPRESIVOS", "CARD60", "ABACAVIR"],
  hmgcr: [
    "ANTICONVULSIVOS",
    "CARD60",
    "ANTIPARKINSONIANOS",
    "ANTI-TDAH",
    "ANALGESICOS",
    "ANTIDEMENCIA"
  ],
  hnmt: ["CARD60", "HISTAMINA"],
  hrh1: ["ANALGESICOS", "TIOPURINAS"],
  hrh2: ["ANTIDEMENCIA"],
  htr2a: [
    "ANTICONVULSIVOS",
    "ANTIDEPRESIVOS",
    "CARD60",
    "ANTIPSICOTICOS",
    "ANTIPARKINSONIANOS",
    "ANTI-TDAH",
    "ANESTESICOS",
    "ANALGESICOS",
    "CARD5",
    "HISTAMINA",
    "ANSIOLITICOS",
    "ANTIDEMENCIA"
  ],
  htr2c: [
    "ANTICONVULSIVOS",
    "ANTIDEPRESIVOS",
    "CARD60",
    "ANTIPARKINSONIANOS",
    "ANTIPSICOTICOS",
    "ANTI-TDAH",
    "ANESTESICOS",
    "ANSIOLITICOS"
  ],
  ifnl3: ["ANTIDEPRESIVOS", "CARD60", "ANTIPSICOTICOS", "DETOX"],
  maob: [
    "TIOPURINAS",
    "PIRIMIDINAS",
    "CARD60",
    "ANTIPSICOTICOS",
    "ANTIPARKINSONIANOS",
    "ANESTESICOS",
    "ANSIOLITICOS"
  ],
  mthfr: [
    "ANTICONVULSIVOS",
    "CARD60",
    "ANTIPSICOTICOS",
    "ANTI-TDAH",
    "ANALGESICOS",
    "ANSIOLITICOS",
    "ANTIDEMENCIA"
  ],
  nat2: [
    "ANTICONVULSIVOS",
    "TAXANOS",
    "DETOX",
    "PIRIMIDINAS",
    "ANTIDEPRESIVOS",
    "CARD60",
    "ANTIPARKINSONIANOS",
    "ANTIPSICOTICOS",
    "ANTI-TDAH",
    "ANESTESICOS",
    "PLATINO",
    "ANALGESICOS",
    "CARD5",
    "HISTAMINA",
    "ANSIOLITICOS",
    "ABACAVIR",
    "ANTIDEMENCIA"
  ],
  nbea: ["ANTIPSICOTICOS", "ANSIOLITICOS", "PLATINO", "CARD60"],
  oprm1: [
    "ANTICONVULSIVOS",
    "TAXANOS",
    "PIRIMIDINAS",
    "ANTIDEPRESIVOS",
    "CARD60",
    "ANTIPSICOTICOS",
    "ANTI-TDAH",
    "ANESTESICOS",
    "PLATINO",
    "ANALGESICOS",
    "ABACAVIR",
    "ANTIDEMENCIA"
  ],
  pparg: ["ANSIOLITICOS", "CARD60", "ANALGESICOS", "ANTI-TDAH"],
  prkce: [
    "DETOX",
    "CARD60",
    "ANTIPARKINSONIANOS",
    "RIBAVIRINA",
    "ANESTESICOS",
    "ANALGESICOS"
  ],
  ptgs2: [
    "ANTICONVULSIVOS",
    "ANTIDEPRESIVOS",
    "CARD60",
    "ANTIPSICOTICOS",
    "ANALGESICOS",
    "ANSIOLITICOS"
  ],
  ryr1: [
    "ANTICONVULSIVOS",
    "ANTIDEPRESIVOS",
    "CARD60",
    "ANTIPARKINSONIANOS",
    "ANTI-TDAH",
    "ANESTESICOS",
    "ANALGESICOS",
    "ANSIOLITICOS"
  ],
  slc22a1: [
    "ANTICONVULSIVOS",
    "ANTIDEPRESIVOS",
    "CARD60",
    "ANTI-TDAH",
    "ANESTESICOS",
    "ANALGESICOS",
    "ANTIDEMENCIA"
  ],
  slc2a2: ["CARD60"],
  slc2a9: ["ANTICONVULSIVOS", "CARD60", "ANALGESICOS"],
  slc30a8: ["ANESTESICOS", "ANTICONVULSIVOS", "ANTIDEPRESIVOS", "DETOX"],
  slc39a8: ["TAXANOS", "CARD60", "ANTIPSICOTICOS", "ANALGESICOS", "CARD5"],
  slc6a2: [
    "ANTICONVULSIVOS",
    "DETOX",
    "ANTIDEPRESIVOS",
    "CARD60",
    "ANTIPSICOTICOS",
    "ANESTESICOS",
    "ANALGESICOS",
    "ANSIOLITICOS",
    "ANTIDEMENCIA"
  ],
  slc6a3: [
    "ANTICONVULSIVOS",
    "PIRIMIDINAS",
    "ANTIDEPRESIVOS",
    "CARD60",
    "ANSIOLITICOS"
  ],
  slc6a4: [
    "ANTICONVULSIVOS",
    "TAXANOS",
    "TIOPURINAS",
    "DETOX",
    "ANTIDEPRESIVOS",
    "CARD60",
    "ANTIPARKINSONIANOS",
    "ANTIPSICOTICOS",
    "ANTI-TDAH",
    "ANESTESICOS",
    "ANALGESICOS",
    "CARD5",
    "ANSIOLITICOS",
    "ANTIDEMENCIA"
  ],
  slco1b1: ["ANTIDEPRESIVOS", "CARD60", "ANTI-TDAH"],
  sod2: [
    "ANTICONVULSIVOS",
    "ANTIDEPRESIVOS",
    "CARD60",
    "ANTIPSICOTICOS",
    "ANALGESICOS",
    "ANTIDEMENCIA"
  ],
  sod3: [
    "ANTICONVULSIVOS",
    "DETOX",
    "ANTIDEPRESIVOS",
    "CARD60",
    "ANTIPSICOTICOS",
    "ANTI-TDAH",
    "ANESTESICOS"
  ],
  tpmt: [
    "ANTIDEPRESIVOS",
    "CARD60",
    "ANTIPSICOTICOS",
    "ANTI-TDAH",
    "ANALGESICOS",
    "ANSIOLITICOS",
    "ANTIDEMENCIA"
  ],
  ugt1a1: ["ANTICONVULSIVOS", "CARD60", "ANALGESICOS", "DETOX"],
  vkorc1: ["ANTICONVULSIVOS", "CARD60", "TAXANOS", "ANTIDEMENCIA"]
};

export default genesPanels;
