<template>
  <v-container id="genetic-profile" fluid tag="section">
     <v-row justify="center">
      <v-col cols="12" md="10">
        <v-row>
          <v-col cols="12" sm="6" align-self="center">
            <page-top-title>
              <template v-slot:toptitle-icon>mdi-dna</template>
              <template v-slot:toptitle-text
                >{{ $t("Simulador") }}
              </template>
            </page-top-title>
          </v-col>
        </v-row>
      </v-col>
     </v-row>
    <v-row class="justify-center">
      <v-col cols="12" md="10">
        <v-row class="justify-center">
          <div style="width:100%;" class="card-title text-h3 font-weight-light">
            Pesos
          </div>
          <v-col cols="1">
            <v-subheader
              ><v-icon color="orange" large class="mr-2"
                >mdi-circle</v-icon
              ></v-subheader
            >
          </v-col>

          <v-col cols="1">
            <v-text-field
              label="Peso NARANJA"
              type="number"
              step="0.1"
              v-model="orange.weight"
            ></v-text-field>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="1">
            <v-subheader
              ><v-icon color="green" large class="mr-2"
                >mdi-circle</v-icon
              ></v-subheader
            >
          </v-col>
          <v-col cols="2">
            <v-text-field
              label="Peso VERDE"
              type="number"
              v-model="green.weight"
            ></v-text-field>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="1">
            <v-subheader
              ><v-icon color="yellow" large class="mr-2"
                >mdi-circle</v-icon
              ></v-subheader
            >
          </v-col>
          <v-col cols="2">
            <v-text-field
              label="Peso AMARILLO"
              type="number"
              v-model="yellow.weight"
            ></v-text-field>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="1">
            <v-subheader
              ><v-icon color="red" large class="mr-2"
                >mdi-circle</v-icon
              ></v-subheader
            >
          </v-col>
          <v-col cols="1">
            <v-text-field
              label="Peso ROJO"
              type="number"
              v-model="red.weight"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="justify-center" v-if="selectedMeanMethod != 'MAX'">
          <div style="width:100%;" class="card-title text-h3 font-weight-light">
            Intervalos
          </div>
          <v-col cols="1">
            <v-subheader
              ><v-icon color="orange" large class="mr-2"
                >mdi-circle</v-icon
              ></v-subheader
            >
          </v-col>
          <v-col cols="1">
            <v-text-field
              label="<"
              type="number"
              v-model="green.left"
            ></v-text-field>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="1">
            <v-subheader
              ><v-icon color="green" large class="mr-2"
                >mdi-circle</v-icon
              ></v-subheader
            >
          </v-col>
          <v-col cols="1">
            <v-text-field
              label=">="
              type="number"
              v-model="green.left"
            ></v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field
              label="<"
              type="number"
              v-model="yellow.left"
            ></v-text-field>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="1">
            <v-subheader
              ><v-icon color="yellow" large class="mr-2"
                >mdi-circle</v-icon
              ></v-subheader
            >
          </v-col>
          <v-col cols="1">
            <v-text-field
              label=">="
              type="number"
              v-model="yellow.left"
            ></v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field
              label="<"
              type="number"
              v-model="red.left"
            ></v-text-field>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="1">
            <v-subheader
              ><v-icon color="red" large class="mr-2"
                >mdi-circle</v-icon
              ></v-subheader
            >
          </v-col>
          <v-col cols="1">
            <v-text-field
              label=">="
              type="number"
              v-model="red.left"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-select
              v-model="selectedMeanMethod"
              :items="meanMethods"
              label="Método"
              solo
            ></v-select>
          </v-col>
          <v-col cols="2">
            <a id="downloadAnchorElem" style="display:none"></a>
            <v-btn elevation="2" @click="downloadConfig"
              >Descargar Modelo</v-btn
            >
          </v-col>
          <v-col cols="2">
            <input
              type="file"
              ref="loadInput"
              style="display:none"
              @change="loadConfig"
            />
            <v-btn elevation="2" @click="$refs.loadInput.click()"
              >Cargar Modelo</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-col
              v-for="gene in getPgx"
              :key="gene.gene"
              style="display: inline-grid;"
              cols="3"
            >
              <base-material-card>
                <template v-slot:after-heading>
                  <div class="font-weight-bold card-title">
                    {{ gene.gene }} ({{ getGeneTotal(gene.snpsInvolved) }})
                    <v-icon
                      :color="getIntervalColor(getGeneTotal(gene.snpsInvolved))"
                      large
                      class="mr-2"
                      >mdi-circle</v-icon
                    >
                  </div>
                </template>
                <v-row fluid class="ma-2" justify="start">
                  <table>
                    <tr v-for="snp in gene.snpsInvolved" :key="snp.snp">
                      <td>{{ snp.snp }} ({{ snp.genotype }})</td>
                      <td>
                        <input
                          type="number"
                          step="0.1"
                          style="width: 40px; border: solid; border-width: 1px; text-align: center;"
                          :value="snpWeights[snp.snp]"
                          @change="updateSNPWeight($event, snp.snp)"
                        />
                      </td>
                      <td>
                        x
                      </td>
                      <td>
                        {{ getCodeWeight(snp.code) }}
                        <v-icon :color="colors[snp.code]" small class="mr-2"
                          >mdi-circle</v-icon
                        >
                      </td>
                      <td>
                        = {{ getSNPValue(snp.snp, getCodeWeight(snp.code)) }}
                      </td>
                    </tr>
                  </table>
                </v-row>
              </base-material-card>
            </v-col>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getUserGenotype } from "@/models/GenotypeModel.js"
import { getUserPGXGenes } from "@/models/PGXGenesModel.js"
import PageTopTitle from "@/components/mylogy/PageTopTitle.vue";
import genesPanels from "@/helpers/genes_paneles.js";
import { mapGetters, mapActions } from "vuex";

var slugify = require("slugify");

export default {
  components: {PageTopTitle},
  props: ['userId'],
  data() {
    return {
      meanMethods: ["ARITMÉTICA", "GEOMÉTRICA", "SUMA", "MAX"],
      selectedMeanMethod: "ARITMÉTICA",
      orange: {
        weight: 1
      },
      green: {
        weight: 3,
        left: 3
      },
      yellow: {
        weight: 4,
        left: 4
      },
      red: {
        weight: 5,
        left: 5
      },
      snpWeights: {},
      colors: ["grey", "#b0e0e6", "green", "yellow", "orange", "red"]
    };
  },
  computed: {
    ...mapGetters("pgx", ["getPgx"])
  },
  created() {
    getUserGenotype(this.userId).then(res => this.setGenotype(res))
    getUserPGXGenes(this.userId).then(res => this.setPGXGenes(res))
  },
  mounted() {
    let self = this;
    self.getPgx.forEach(element => {
      element.snpsInvolved.forEach(snp => {
        self.$set(this.snpWeights, snp.snp, 1);
      });
    });
  },
  methods: {
    ...mapActions("pgx", ["setGenotype", "setPGXGenes"]),
    loadConfig(ev) {
      let self = this;
      const file = ev.target.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        const savedState = JSON.parse(e.target.result);
        self.green = savedState.green;
        self.yellow = savedState.yellow;
        self.orange = savedState.orange;
        self.red = savedState.red;
        self.selectedMeanMethod = savedState.selectedMeanMethod;
        self.snpWeights = savedState.snpWeights;
      };
      reader.readAsText(file);
    },
    downloadConfig() {
      const dataStr =
        "data:text/json;charset=utf-8," +
        encodeURIComponent(JSON.stringify(this.$data));
      const dlAnchorElem = document.getElementById("downloadAnchorElem");
      dlAnchorElem.setAttribute("href", dataStr);
      dlAnchorElem.setAttribute("download", "modelo.json");
      dlAnchorElem.click();
    },
    updateSNPWeight(e, snp) {
      const newVal = parseFloat(e.target.value);
      this.snpWeights[snp] = newVal;
    },
    getSNPValue(snp, val) {
      return (this.snpWeights[snp] * val).toFixed(2);
    },
    getCodeWeight(code) {
      const weightsMap = {
        "2": parseInt(this.green.weight),
        "3": parseInt(this.yellow.weight),
        "4": parseInt(this.orange.weight),
        "5": parseInt(this.red.weight)
      };
      return weightsMap[code.toString()];
    },
    getIntervalColor(value) {
      if (this.selectedMeanMethod == "MAX") {
        const colorsMap = {
          [this.orange.weight]: "orange",
          [this.green.weight]: "green",
          [this.yellow.weight]: "yellow",
          [this.red.weight]: "red"
        }
        return colorsMap[value]
      } 
      if (value < this.green.left) {
        return "orange";
      } else {
        if (value < this.yellow.left) {
          return "green";
        } else {
          if (value < this.red.left) {
            return "yellow";
          } else {
            return "red";
          }
        }
      }
    },
    getGeneTotal(snps) {
      let self = this;
      const weights = snps.map(snp => {
        return self.getCodeWeight(snp.code) * self.snpWeights[snp.snp];
      });
      const max = weights.reduce(function(prev, current) {
        return prev > current ? prev : current;
      });
      if (this.selectedMeanMethod == "GEOMÉTRICA") {
        const gmean = require("gmean");
        return gmean(weights).toFixed(2);
      } else if (this.selectedMeanMethod == "SUMA") {
        return weights.reduce((a, b) => a + b).toFixed(2);
      } else if (this.selectedMeanMethod == "MAX") {
        return max;
      } else {
        return (weights.reduce((a, b) => a + b) / weights.length).toFixed(2);
      }
    }
  }
};
</script>

<style lang="scss">
fieldset {
  border-color: #4d2cb9 !important;
}
</style>
